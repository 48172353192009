import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const INSERT_USER = gql`
  mutation MyMutation($email: String!) {
    __typename
    insert_user(objects: { email: $email }) {
      affected_rows
    }
  }
`;

function EmailForm() {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [insertUser, { error: mutationError }] = useMutation(INSERT_USER);
  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    insertUser({ variables: { email: email } });
    setEmail('');
    setMessage(
      mutationError ? `Error signing up, please try again!` : 'Thank You!'
    );
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };
  return (
    <form id="signup-form" onSubmit={onSubmit} method="post" action="#">
      <input
        type="email"
        name="email"
        id="email"
        placeholder="Email Address"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <input type="submit" value="Sign Up" />
      <span className={`${message ? 'visible success' : ''} message`}>
        {message}
      </span>
    </form>
  );
}

export default EmailForm;
