import React, { PureComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

export default class SlideShow extends PureComponent {
  constructor() {
    super();
    this.state = {
      pos: 0,
      lastPos: 0,
    };
    this.rotateSlide = this.rotateSlide.bind(this);
  }

  rotateSlide() {
    const { settings } = this.props;
    setInterval(() => {
      let { pos, lastPos } = this.state;
      lastPos = pos;
      pos++;

      if (pos >= settings.images.length) {
        pos = 0;
      }

      // Hide last image after a short delay.
      setTimeout(() => {
        lastPos = pos;
        this.setState({ lastPos });
      }, settings.delay / 2);

      this.setState({ lastPos, pos });
    }, settings.delay);
  }
  componentDidMount() {
    this.rotateSlide();
  }
  render() {
    const { pos, lastPos } = this.state;

    return (
      <div id="bg">
        <StaticQuery
          query={graphql`
            query {
              allFile(filter: { absolutePath: { regex: "/images/" } }) {
                edges {
                  node {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <React.Fragment>
              {data.allFile.edges.map((imageData, i) => {
                return (
                  <div
                    key={new Date() + i}
                    className={
                      i === pos
                        ? 'container visible top'
                        : i === lastPos
                        ? 'container visible'
                        : 'container'
                    }
                  >
                    <BackgroundImage
                      Tag="div"
                      fluid={imageData.node.childImageSharp.fluid}
                      style={{
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'repeat',
                        backgroundSize: 'cover',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                      preserveStackingContext={true}
                    ></BackgroundImage>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        />
      </div>
    );
  }
}
